<script setup lang="ts"></script>

<template>
  <section class="home-section" id="vs-home-signature">
    <div class="home-signature-summary">
      <img class="home-signature-logo" src="@/assets/images/obmed-saude.png" alt="obmed+saúde" />
      <h5 class="home-signature-title">
        Conheça a primeira e única plataforma do Nordeste para atendimento médico online 24 horas, 7 dias da semana!
      </h5>
      <p class="home-signature-description">
        Nós entendemos a importância desse cuidado com a saúde e oferecemos um auxílio saúde empresarial completo, com
        amplos serviços de telemedicina, incluindo consultas, com médicos especialistas e sessões de psicologia online,
        além de descontos exclusivos em serviços e produtos voltados para o bem-estar dos seus colaboradores.
      </p>
      <p class="home-signature-description">
        Tudo isso por um valor mensal fixo e acessível. Leve saúde e bem-estar à sua empresa, com tecnologia,
        praticidade e segurança.
      </p>
    </div>
    <a class="home-signature-link" href="https://saude.obmed.com.br/" target="_blank">Conheça mais</a>
    <div class="home-signature-cards">
      <div class="home-signature-card"><p>100% Online</p></div>
      <div class="home-signature-card"><p>Consultas com especialistas</p></div>
      <div class="home-signature-card"><p>Sessões de Psicologia</p></div>
      <div class="home-signature-card"><p>Economia para sua equipe</p></div>
      <div class="home-signature-card"><p>Saúde em números</p></div>
      <div class="home-signature-card"><p>Posicionamento estratégico da sua empresa</p></div>
    </div>
  </section>
</template>

<style lang="scss">
#vs-home-signature {
  max-width: 1080px !important;

  .home-signature-summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    padding: 34px;
    border: 4px solid #d1614b;
    border-radius: 44px;
    background-color: #fff;

    .home-signature-logo {
      width: 234px;
      margin-bottom: 20px;
    }

    .home-signature-title,
    .home-signature-description {
      color: #d1614b;
      text-align: center;
    }

    .home-signature-title {
      margin-bottom: 48px;
      max-width: 880px;
      font-weight: 900;
      font-size: 40px;
      line-height: 47px;
    }

    .home-signature-description {
      padding-inline: 24px;
      font-weight: 700;
      font-size: 24px;
      line-height: 34px;
    }
  }

  .home-signature-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 92px;
    margin-top: 20px;
    background: linear-gradient(180deg, #d83070 -0.3%, #f16445 99.27%);
    border-radius: 12px;
    color: #fff;
    font-weight: 700;
    font-size: 44px;
    line-height: 21px;
  }

  .home-signature-cards {
    display: grid;
    gap: 40px 34px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 28px;

    .home-signature-card {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      width: 100%;
      height: 160px;
      box-shadow: 4px 1px 2px 2px rgba(0, 0, 0, 0.05), 0px 4px 5px 2px rgba(0, 0, 0, 0.05);
      border-radius: 24px;
      background: #ffffff;
      cursor: default;

      p {
        max-width: 380px;
        background: linear-gradient(180deg, #d83070 -0.3%, #f16445 99.27%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-weight: 900;
        font-size: 44px;
        line-height: 40px;
        text-align: center;
        user-select: none;
      }
    }
  }

  @media screen and (max-width: 760px) {
    .home-signature-summary {
      border: none;
      padding-inline: 0;

      .home-signature-title {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 38px;
      }

      .home-signature-description {
        padding: 0;
        font-size: 20px;
        line-height: 26px;
      }
    }

    .home-signature-cards {
      grid-template-columns: 1fr;

      .home-signature-card {
        height: 140px;

        p {
          font-size: 32px;
          line-height: 32px;
        }
      }
    }

    .home-signature-link {
      height: 80px;
      color: #fff;
      font-weight: 700;
      font-size: 34px;
      line-height: 10px;
    }
  }
}
</style>
